//// JS MAIN FILE ////

// Import Webflow
import { webflow } from "./modules/_webflow.js";
webflow();

// Import gsap library
import { gsapLib } from "./ink-gsap/_ink-gsap.js";
window.addEventListener('load', gsapLib);
import { envTags } from "./ink-gsap/_envTags.js";
envTags();

// Import modules
import { cursor } from "./modules/_cursor.js";
cursor();
import { menu } from "./modules/_menu.js";
menu();
import { animations } from "./modules/_animations.js";
animations();
import { infolettre } from "./modules/_infolettre.js";
infolettre();

// Import templates
import { home } from "./templates/_home.js";
home();
import { services } from "./templates/_services.js";
services();
import { projects } from "./templates/_projects.js";
projects();
import { team } from "./templates/_team.js";
team();
import { Nouvelle } from "./templates/_nouvelle.js";
Nouvelle();
